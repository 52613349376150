<template>
  <!-- Footer mobile -->
  <div class="footer-mobile omni-navbar">
    <q-tabs
      v-model="tabMobile"
      indicator-color="transparent"
      active-class="active-option"
      class="tabs-mobile shadow-2 fixed-bottom q-ma-lg"
      style="z-index: 3; justify-content: space-around"
    >
      <q-route-tab icon="home" to="/" exact />
      <q-route-tab icon="card_giftcard" to="/products" exact />
      <q-route-tab icon="person" to="/profile" exact />
      <!--
      <q-route-tab
        name="controlpanel"
        icon="account_balance_wallet"
        to="/controlpanel"
        exact
        @click="dialog = true"
      />
      -->
      <q-route-tab icon="receipt" to="/transacciones" exact />
    </q-tabs>
  </div>

  <!-- Footer desktop -->
  <div class="footer-desktop" style="display: none;">
    <q-splitter v-model="splitterModel">
      <template v-slot:before>
        <q-tabs
          v-model="tabDesktop"
          active-class="active-option"
          vertical
          class="tabs-desktop fixed-left"
          active-bg-color="#E6E6E6"
          style="min-width: 130px"
        >
          <q-route-tab icon="home" to="/" style="margin-top: 150px" exact
            >
            <p>{{ $t("General.home") }}</p></q-route-tab
          >

          <q-route-tab icon="card_giftcard" to="/products" exact
            >
            <p>{{ $t("General.products") }}</p></q-route-tab
          >
          <q-route-tab icon="person" to="/profile" exact
            >
            <p>{{ $t("General.profile") }}</p></q-route-tab
          >
          <!--
          <q-route-tab icon="account_balance_wallet" to="/controlpanel" exact
            >
            <p>{{ $t("General.wallet") }}</p></q-route-tab
          >
          -->
          <q-route-tab icon="receipt" to="/transacciones" exact
            >
            <p>{{ $t("General.transactions") }}</p></q-route-tab
          >
          <div class="q-pl-md fixed-bottom-left">Version: {{appVersion}}</div> 
        </q-tabs>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { ref } from "vue";
import {version} from '../../../../package.json'
import settings from "@/mixins/settings";


export default {
  mixins: [settings],
  setup() {
    return {
      fixed: ref(false),
      splitterModel: ref(0),
      tabMobile: ref(""),
      tabDesktop: ref(""),
      appVersion:version, 
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  text-transform: capitalize;
}

.tabs-mobile {
  border-radius: 15px;
  .q-tab {
    padding: 0;
  }
}

.tabs-desktop {
  border: 1px solid #e6e6e6;
  .q-tab {
    padding: 0;
  }
}

.q-tabs--vertical .q-tab {
  padding: 25px 15px;
}

@media only screen and (min-width: 767px) {
  .footer-desktop {
    display: block;
  }

  .footer-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer-mobile {
    display: block;
  }

  .footer-desktop {
    display: none;
  }
}
</style>
